<template>
  <div class="add-project-unit">
    <div class="card">
      <fd-form class="modal-content-wrapper" @submit.prevent="submitForm">
        <modal-body class="p-2">
          <div class="container fluid">
            <h3 class="mt-2 my-4">Add New Unit</h3>
            <fd-form-section class="row" title="Unit Info">
              <fd-input
                v-model="unit.street"
                class="col-12 sm-col-6 md-col-4 px-1 mb-2"
                label="Street Name"
                name="projectUnitStreet"
                type="text"
              >
              </fd-input>
              <fd-select
                v-model="unit.projectPhaseId"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Phase"
                :options="phaseOptions"
                :validators="[validator.required]"
                :optionValue="(item) => item.id"
                :optionLabel="(item) => item.name"
              >
              </fd-select>
              <fd-select
                v-model="unit.orientation"
                class="col-12 sm-col-6 md-col-4 px-1 mb-2"
                label="Orientation"
                :options="orientationOptions"
              >
              </fd-select>
            </fd-form-section>

            <div class="row p-1">
              <assign-bankers
                v-model="bankers"
                required
                :type="type"
                class="col-12"
                :projectId="projectId"
                :mapValue="(val) => val.id"
              ></assign-bankers>
            </div>

            <div class="row p-1">
              <assign-lawyers
                v-model="lawyers"
                required
                :type="type"
                class="col-12"
                :projectId="projectId"
                :mapValue="(val) => val.id"
              ></assign-lawyers>
            </div>
            <hr />
            <!-- ========================== Plots ========================== -->
            <fd-form-section class="plots-container p-1" title="Plot">
              <div class="col-12 mb-2">
                <p class="mr-2">Used Plots</p>
                <span
                  class="used-plots d-inline-block"
                  v-for="(plot, index) in usedPlots"
                  :key="index"
                  >{{ plot }}</span
                >
              </div>
              <div class="col-12 text-right mb-2">
                <button
                  type="button"
                  class="btn bordered main d-inline-block mr-1"
                  @click="clearAllPlots"
                >
                  Clear Plots
                </button>
                <v-popover
                  class="d-inline-block"
                  offset="5"
                  :open="showHint.setPlot"
                  :disabled="!showHint.setPlot"
                >
                  <button
                    type="button"
                    class="btn main d-inline-block"
                    @click="openAddMultipleModal"
                  >
                    Add Multiple
                  </button>
                  <template slot="popover">
                    <p class="mb-2">Use this to add multiple plots at once.</p>
                    <button
                      class="popover-close mt-2"
                      v-close-popover
                      @click="showHint.setPlot = false"
                    >
                      Close
                    </button>
                  </template>
                </v-popover>
              </div>
              <!-- Plot inputs -->
              <unit-plot-input
                class="col-6 sm-col-3 md-col-2 p-1"
                v-model="unit.plot[index]"
                v-for="(plot, index) in unit.plot"
                :key="index"
                :usedPlots="usedPlots"
                @removePlot="removePlot(index)"
              ></unit-plot-input>
              <!-- Add Plot button -->
              <div class="col-12 sm-col-3 md-col-2 p-1">
                <button
                  type="button"
                  class="increment-btn"
                  @click="incrementPlot"
                  v-tooltip=""
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </fd-form-section>
          </div>
        </modal-body>
        <!-- ==================== Action Footer ============================ -->
        <modal-footer class="p-2">
          <div class="col-12 text-right">
            <button type="button" class="btn mr-1" @click="$emit('cancel')">
              Cancel
            </button>
            <button class="btn main">Confirm</button>
          </div>
        </modal-footer>
      </fd-form>
    </div>

    <!-- ==================== Modal: Add multiple plots ==================== -->
    <modal isNested v-model="addMultiplePlotModal.isShown">
      <div class="row card p-2">
        <fd-input
          v-model="plotToAdd"
          class="col-12 px-1"
          label="Number of plots to add"
          type="number"
        >
        </fd-input>
        <div class="col-12 text-right px-1 mb-3">
          <p class="">*Limit: 100</p>
        </div>
        <button type="button" class="col-12 btn main" @click="addMultiplePlots">
          <i class="fas fa-plus mr-1"></i> Add
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  required,
  positiveInteger
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { filter, includes } from "lodash";
import { orientation } from "@/enums";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectPhaseAPI from "@/modules/Project/api/projectPhase";
import ProjectAPI from "@/modules/Project/api/project";

const LIMIT = 100;

export default {
  components: {
    UnitPlotInput: () => import("./UnitPlotInput"),
    AssignBankers: () =>
      import("@/modules/Project/components/AssignBankers/AssignToUnit"),
    AssignLawyers: () =>
      import("@/modules/Project/components/AssignLawyers/AssignToUnit")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      type: [Number, String]
    },
    projectUnitTypeId: {
      type: [Number, String]
    },
    projectFloorPlanId: {
      type: [Number, String]
    }
  },
  data: function () {
    return {
      phaseAPI: new ProjectPhaseAPI(this.type),
      projectAPI: new ProjectAPI(this.type),

      orientationOptions: this.$mapJsonToArray(orientation, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      phaseOptions: [],

      usedPlots: [],

      unit: {
        projectId: "",
        projectUnitTypeId: "",
        projectFloorPlanId: "",
        street: "",
        projectPhaseId: "",
        plot: []
      },
      bankers: [],
      lawyers: { spaLawyerId: "", laLawyerId: "" },

      validator: {
        required: required
      },

      addMultiplePlotModal: {
        isShown: false
      },
      plotToAdd: "",

      showHint: {
        setPlot: false
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        this.unit.projectId = this.projectId;
        this.unit.projectUnitTypeId = this.projectUnitTypeId;
        this.unit.projectFloorPlanId = this.projectFloorPlanId;
        await Promise.all([this.getUsedPlot(), this.getPhaseOptions()]);
        this.showHint.setPlot = true;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    incrementPlot() {
      if (this.isOverLimit(this.unit.plot.length + 1)) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `Plot exceeded limit of ${LIMIT}.`
        });
      } else {
        this.unit.plot.push((this.unit.plot.length + 1).toString());
      }
    },
    removePlot(index) {
      this.unit.plot.splice(index, 1);
    },
    openAddMultipleModal() {
      this.addMultiplePlotModal.isShown = true;
    },
    closeAddMultipleModal() {
      this.addMultiplePlotModal.isShown = false;
    },
    addMultiplePlots() {
      if (positiveInteger(this.plotToAdd)) {
        if (this.isOverLimit(this.plotToAdd)) {
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: `Plot exceeded limit of ${LIMIT}.`
          });
          return false;
        }
        for (let i = 0; i < this.plotToAdd; i++) {
          this.incrementPlot();
        }
        this.plotToAdd = "";
        this.closeAddMultipleModal();
        return;
      }
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Input must be a positive integer."
      });
    },
    clearAllPlots() {
      this.unit.plot = [];
    },
    validatePlot() {
      // Check empty
      if (this.unit.plot.length < 1) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Plot is empty",
          text: "Please add at least one plot."
        });
        return false;
      }
      // Check duplicate
      else if (this.isPlotDuplicate()) {
        return false;
      }
      return true;
    },
    isPlotDuplicate() {
      let duplicates = filter(this.unit.plot, (val, i, iteratee) =>
        includes(iteratee, val, i + 1)
      );
      if (duplicates > 0) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Plot duplicated",
          text: `${duplicates.join(",")} is duplicated.`
        });
      }
      return duplicates.length > 0;
    },
    isOverLimit(val) {
      return val > LIMIT;
    },
    async getUsedPlot() {
      try {
        let usedPlot = await this.projectAPI.getUsedPlots(this.projectId);
        this.usedPlots = usedPlot.map((item) => {
          return item.plot;
        });
      } catch (error) {
        throw error;
      }
    },
    async getPhaseOptions() {
      try {
        let data = await this.phaseAPI.getProjectPhases({
          queries: {
            "project:id": this.projectId,
            "projectUnitTypes:id": this.projectUnitTypeId
          },
          perPage: 20
        });
        this.phaseOptions = this._.cloneDeep(data.data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get phase options. Please try again later."
        });
        throw error;
      }
    },
    submitForm() {
      if (this.validatePlot()) {
        this.$emit("submit", {
          ...this.unit,
          bankerIds: this.bankers,
          ...this.lawyers
        });
      }
    }
  }
};
</script>

<style lang="scss">
.add-project-unit {
  @extend %formDesign;

  .used-plots {
    background: #ddd;
    border-radius: 4px;
    padding: 4px 10px;
    margin: 2px;
  }
  .plots-container {
    border-radius: 5px;
    .increment-btn {
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      border: solid 1px $color-main;
      color: $color-main;
      &:hover {
        background: $color-main;
        color: white;
      }
    }
  }
}
button.popover-close {
  font-size: 12px;
  color: white;
  padding: 5px 10px;
  text-align: right;
  border: solid 1px white;
  border-radius: 5px;

  &:hover {
    background: white;
    color: $color-secondary;
  }
}
</style>
